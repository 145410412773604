<template>
  <main class="page-pt">
    <slot />
  </main>
</template>

<script>
export default {

}
</script>

<style>

</style>
